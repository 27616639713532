export default {
  header: {
    self: {},
    items: [
      {
        title: "Web & Mobile",
        root: true,
        alignment: "left",
        page: "webandmobile"
      },
      {
        title: "Cloud",
        root: true,
        alignment: "left",
        page: "cloud"
      },
      {
        title: "Data Solutions",
        root: true,
        alignment: "left",
        page: "datasolutions"
      },
      {
        title: "Approach",
        root: true,
        alignment: "left",
        page: "approach"
      }
    ]
  },
  aside: {
    self: {},
    items: [
      {
        title: "Conversational AI",
        root: true,
        page: "conversation",
        icon: "flaticon2-architecture-and-city",
        translate: "MENU.DASHBOARD",
        bullet: "dot"
      },
      {
        title: "Natural Language Processing",
        root: true,
        page: "nlp",
        icon: "flaticon2-expand"
      },
      {
        title: "AI Solutions",
        root: true,
        page: "aisolutions",
        icon: "flaticon2-expand"
      }
    ]
  }
};