import React, { useMemo } from "react";
import PageComponent from "../AbstractPage";
import ContactFormSection from "../sections/ContactFormSection";

export default function PrivacyPolicyPageComponent() {
  const getContactUsFormSection = () => {
    return <ContactFormSection />;
  };

  const getIntroductionText = () => {
    return (
      <>
        <span>
          At <b>Dhan AI Inc.</b> ("company", "we", "us", or "our"), we are
          committed to protecting your personal information and your right to
          privacy. If you have any questions or concerns about our policy, or
          our practices with regards to your personal information, please
          contact us at privacy@dhansolutions.com.
        </span>
        <br />
        <span>
          When you visit our website https://www.dhansolutions.com, and use our
          services, you trust us with your personal information. We take your
          privacy very seriously. In this privacy notice, we describe our
          privacy policy. We seek to explain to you in the clearest way possible
          what information we collect, how we use it and what rights you have in
          relation to it. We hope you take some time to read through it
          carefully, as it is important. If there are any terms in this privacy
          policy that you do not agree with, please discontinue use of our Sites
          and our services.
        </span>
        <br />
        <span>
          This privacy policy applies to all information collected through our
          website (such as https://www.dhansolutions.com), and/or any related
          services, sales, marketing or events (we refer to them collectively in
          this privacy policy as the "Sites").
          <b>
            Please read this privacy policy carefully as it will help you make
            informed decisions about sharing your personal information with us.
          </b>
        </span>
      </>
    );
  };

  const getPrivacyPolicyToc = () => {
    return (
      <>
        <h2> Table of Contents</h2>
        <div className="kt-privacypolicy-toc-act">
          <a href="#informationcollect">1. What information do we collect?</a>
          <br />
          <a href="#informationuse">
            2. How do we use your information? hover example
          </a>
          <br />
          <a href="#informationshare">
            3. Will your information be shared with anyone?
          </a>
          <br />
          <a href="#cookieTracking">
            4. Do we use cookies and other tracking technologies?
          </a>
          <br />
          <a href="#informationlifetime">
            5. How long do we keep your information?
          </a>
          <br />
          <a href="#informationsafe">
            6. How do we keep your information safe?
          </a>
          <br />
          <a href="#informationminors">
            7. Do we collect information from minors?
          </a>
          <br />
          <a href="#privacyrights">8. What are your privacy rights?</a>
          <br />
          <a href="#policyupdate">9. Do we make updates to this policy?</a>
          <br />
          <a href="#policycontact">
            10. How can you contact us about this policy?
          </a>
          <br />
        </div>
      </>
    );
  };

  const getPrivacyQuestion1Div = () => {
    return (
      <div
        className="kt-privacypolicy-questionanswer-div"
        id="informationcollect"
      >
        <div className="kt-privacypolicy-question-div">
          1. What information do we collect?
        </div>
        <div className="kt-privacypolicy-answer-div">
          <span>
            <b>Personal information you disclose to us</b>
          </span>
          <br />
          <span>
            <p>
              <b>In Short:</b> We collect personal information that you provide
              to us such as name, contact information, your job title, company
              name, and website. We collect personal information that you
              voluntarily provide to us when expressing an interest in obtaining
              information about us or our products and services, when
              participating in activities on the Sites or otherwise contacting
              us. The personal information that we collect depends on the
              context of your interactions with us and the Sites, the choices
              you make and the products and features you use.
            </p>
            <p>
              The personal information we collect can include the following:
            </p>
            <br /> Name and Contact Data: We collect your first and last name,
            email address, phone number, and other similar contact data.
            <br /> Your Job and Company Data: In some cases, we also collect
            your job title and Company website address.
            <br />
            <p>
              Information automatically collected In Short: Some information –
              such as IP address and/or browser and device characteristics – is
              collected automatically when you visit our Sites. We automatically
              collect certain information when you visit, use or navigate the
              Sites. This information does not reveal your specific identity
              (like your name or contact information) but may include device and
              usage information, such as your IP address, browser and device
              characteristics, operating system, language preferences, referring
              URLs, device name, country, location, information about how and
              when you use our Sites and other technical information. This
              information is primarily needed to maintain the security and
              operation of our Sites, and for our internal analytics and
              reporting purposes.
            </p>
            <br />
            <p>
              Like many businesses, we also collect information through cookies
              and similar technologies. For more information, please see
              our Cookies and Tracking Notice, which includes information on how
              to control or opt out of these cookies and tracking technologies.
            </p>
            <br />
            <p>
              Information collected from other sources In Short: We may collect
              limited data from public databases, marketing partners, and other
              outside sources. We may obtain information about you from other
              sources, such as public databases, joint marketing partners, as
              well as from other third parties. Examples of the information we
              receive from other sources include: social media profile
              information; marketing leads and search results and links,
              including paid listings (such as sponsored links).
            </p>
          </span>
        </div>
      </div>
    );
  };

  const getPrivacyQuestion2Div = () => {
    return (
      <div className="kt-privacypolicy-questionanswer-div" id="informationuse">
        <div className="kt-privacypolicy-question-div">
          2. How do we use your information? hover example
        </div>
        <div className="kt-privacypolicy-answer-div">
          <span>
            <b>In Short:</b>
          </span>
          <span>
              We process your information for purposes based on legitimate
            business interests, the fulfillment of our contract with you,
            compliance with our legal obligations, and/or your consent.
            <br />
            <p>
              We use personal information collected via our Sites for a variety
              of business purposes described below. We process your personal
              information for these purposes in reliance on our legitimate
              business interests ("Business Purposes"), in order to enter into
              or perform a contract with you ("Contractual"), with your consent
              ("Consent"), and/or for compliance with our legal obligations
              ("Legal Reasons"). We indicate the specific processing grounds we
              rely on next to each purpose listed below. We use the information
              we collect or receive:
            </p>
            <br />
            <p>
              To send you marketing and promotional communications for Business
              Purposes and/or with your Consent. We and/or our third party
              marketing partners may use the personal information you send to us
              for our marketing purposes, if this is in accordance with your
              marketing preferences. You can opt-out of our marketing emails at
              any time (see the "WHAT ARE YOUR PRIVACY RIGHTS" below).
            </p>
            <br />
            <p>
              To send administrative information to you for Business Purposes.
              We may use your personal information to send you product, service
              and new feature information and/or information about changes to
              our terms, conditions, and policies.
            </p>
            <br />
            <p>
              To post testimonials with your Consent. We post testimonials on
              our Sites that may contain personal information. Prior to posting
              a testimonial, we will obtain your consent to use your name and
              testimonial. If you wish to update, or delete your testimonial,
              please contact us at privacy@dhansolutions.com and be sure to
              include your name, testimonial location, and contact information.
            </p>
            <br />
            <p>
              Request Feedback for our Business Purposes and/or with your
              Consent. We may use your information to request feedback and to
              contact you about your use of our Sites.
            </p>
            <br />
            <p>
              To protect our Sites for Business Purposes and/or for Legal
              Reasons. We may use your information as part of our efforts to
              keep our Sites safe and secure (for example, for fraud monitoring
              and prevention).
            </p>
            <br />
            <p>
              To enforce our terms, conditions and policies for Business
              Purposes, Legal Reasons and/or possibly Contractual.
            </p>
            <br />
            <p>
              To respond to legal requests and prevent harm for Legal Reasons.
              If we receive a subpoena or other legal request, we may need to
              inspect the data we hold to determine how to respond.
            </p>
            <br />
            <p>
              For other Business Purposes. We may use your information for other
              Business Purposes, such as data analysis, identifying usage
              trends, determining the effectiveness of our promotional campaigns
              and to evaluate and improve our Sites, products, services,
              marketing and your experience.
            </p>
            <br />
          </span>
        </div>
      </div>
    );
  };

  const getPrivacyQuestion3Div = () => {
    return (
      <div
        className="kt-privacypolicy-questionanswer-div"
        id="informationshare"
      >
        <div className="kt-privacypolicy-question-div">
          3. Will your information be shared with anyone?
        </div>
        <div className="kt-privacypolicy-answer-div">
          <span>
            <b>In Short:</b>
          </span>
          <span>
              We only share information with your consent, to comply with laws,
            to protect your rights, or to fulfill business obligations. We only
            share and disclose your information in the following situations:
            <br />
            <p>
              Compliance with Laws. We may disclose your information where we
              are legally required to do so in order to comply with applicable
              law, governmental requests, a judicial proceeding, court order, or
              legal process, such as in response to a court order or a subpoena
              (including in response to public authorities to meet national
              security or law enforcement requirements).
            </p>
            <br />
            <p>
              Vital Interests and Legal Rights. We may disclose your information
              where we believe it is necessary to investigate, prevent, or take
              action regarding potential violations of our policies, suspected
              fraud, situations involving potential threats to the safety of any
              person and illegal activities, or as evidence in litigation in
              which we are involved
            </p>
            <br />
            <p>
              Vendors, Consultants and Other Third-Party Service Providers. We
              may share your data with third party vendors, service providers,
              contractors or agents who perform services for us or on our behalf
              and require access to such information to do that work. Examples
              include: data analysis, email delivery, hosting services, customer
              service and marketing efforts. We may allow selected third parties
              to use tracking technology on the Sites, which will enable them to
              collect data about how you interact with the Sites over time. This
              information may be used to, among other things, analyze and track
              data, determine the popularity of certain content and better
              understand online activity. Unless described in this Policy, we do
              not share, sell, rent or trade any of your information with third
              parties for their promotional purposes.
            </p>
            <br />
            <p>
              Business Transfers. We may share or transfer your information in
              connection with, or during negotiations of, any merger, sale of
              company assets, financing, or acquisition of all or a portion of
              our business to another company.
            </p>
            <br />
            <p>
              Affiliates. We may share your information with our affiliates, in
              which case we will require those affiliates to honor this privacy
              policy. Affiliates include our parent company and any
              subsidiaries, joint venture partners or other companies that we
              control or that are under common control with us.
            </p>
            <br />
            <p>
              Business Partners. We may share your information with our business
              partners to offer you certain products, services or promotions.
            </p>
            <br />
            <p>
              With your Consent. We may disclose your personal information for
              any other purpose with your consent.
            </p>
            <br />
          </span>
        </div>
      </div>
    );
  };

  const getPrivacyQuestion4Div = () => {
    return (
      <div className="kt-privacypolicy-questionanswer-div" id="cookieTracking">
        <div className="kt-privacypolicy-question-div">
          4. Do we use cookies and other tracking technologies?
        </div>
        <div className="kt-privacypolicy-answer-div">
          <span>
            Dhan solutions and our third-party partners, such as our advertising
            and analytics partners, use cookies and other tracking technologies
            (e.g., web beacons, device identifiers and pixels) to provide
            functionality and to recognize you across different Services and
            devices. For more information, please see our Cookies and Tracking
            Notice, which includes information on how to control or opt out of
            these cookies and tracking technologies.
          </span>
        </div>
      </div>
    );
  };

  const getPrivacyQuestion5Div = () => {
    return (
      <div
        className="kt-privacypolicy-questionanswer-div"
        id="informationlifetime"
      >
        <div className="kt-privacypolicy-question-div">
          5. How long do we keep your information?
        </div>
        <div className="kt-privacypolicy-answer-div">
          <span>
            <b>In Short:</b> We keep your information for as long as necessary
            to fulfill the purposes outlined in this privacy policy unless
            otherwise required by law. We will only keep your personal
            information for as long as it is necessary for the purposes set out
            in this privacy policy, unless a longer retention period is required
            or permitted by law (such as tax, accounting or other legal
            requirements). No purpose in this policy will require us keeping
            your personal information for longer than 5 years. When we have no
            ongoing legitimate business need to process your personal
            information, we will either delete or anonymize it, or, if this is
            not possible (for example, because your personal information has
            been stored in backup archives), then we will securely store your
            personal information and isolate it from any further processing
            until deletion is possible.
          </span>
        </div>
      </div>
    );
  };

  const getPrivacyQuestion6Div = () => {
    return (
      <div className="kt-privacypolicy-questionanswer-div" id="informationsafe">
        <div className="kt-privacypolicy-question-div">
          6. How do we keep your information safe?
        </div>
        <div className="kt-privacypolicy-answer-div">
          <span>
            <b>In Short:</b> We aim to protect your personal information through
            a system of organizational and technical security measures. We have
            implemented appropriate technical and organizational security
            measures designed to protect the security of any personal
            information we process. However, please also remember that we cannot
            guarantee that the internet itself is 100% secure. Although we will
            do our best to protect your personal information, transmission of
            personal information to and from our Sites is at your own risk. You
            should only access the services within a secure environment.
          </span>
        </div>
      </div>
    );
  };

  const getPrivacyQuestion7Div = () => {
    return (
      <div
        className="kt-privacypolicy-questionanswer-div"
        id="informationminors"
      >
        <div className="kt-privacypolicy-question-div">
          7. Do we collect information from minors?
        </div>
        <div className="kt-privacypolicy-answer-div">
          <span>
            <b>In Short:</b> We do not knowingly collect data from or market to
            children under 18 years of age. We do not knowingly solicit data
            from or market to children under 18 years of age. By using the
            Sites, you represent that you are at least 18 or that you are the
            parent or guardian of such a minor and consent to such minor
            dependent's use of the Sites. If we learn that personal information
            from users less than 18 years of age has been collected, we will
            take reasonable measures to promptly delete such data from our
            records. If you become aware of any data we have collected from
            children under age 18, please contact us
            at privacy@dhansolutions.com.
          </span>
        </div>
      </div>
    );
  };

  const getPrivacyQuestion8Div = () => {
    return (
      <div className="kt-privacypolicy-questionanswer-div" id="privacyrights">
        <div className="kt-privacypolicy-question-div">
          8. What are your privacy rights?
        </div>
        <div className="kt-privacypolicy-answer-div">
          <span>
            <b>In Short:</b> You may review, change, or terminate your account
            at any time. If you are resident in the European Economic Area and
            you believe we are unlawfully processing your personal information,
            you also have the right to complain to your local data protection
            supervisory authority. You can find their contact details
            here: http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm
            <br />
            <b>Cookies and similar technologies:</b> Most Web browsers are set
            to accept cookies by default. If you prefer, you can usually choose
            to set your browser to remove cookies and to reject cookies. If you
            choose to remove cookies or reject cookies, this could affect
            certain features or services of our Sites. For further information,
            please see our 
            <b>Cookie Policy: https://www.dhansolutions.com/cookie-policy</b>
          </span>
        </div>
      </div>
    );
  };

  const getPrivacyQuestion9Div = () => {
    return (
      <div className="kt-privacypolicy-questionanswer-div" id="policyupdate">
        <div className="kt-privacypolicy-question-div">
          9. Do we make updates to this policy?
        </div>
        <div className="kt-privacypolicy-answer-div">
          <span>
            <b>In Short:</b> Yes, we will update this policy as necessary to
            stay compliant with relevant laws. <br />
            We may update this privacy policy from time to time. The updated
            version will be indicated by an updated “Revised” date and the
            updated version will be effective as soon as it is accessible. If we
            make material changes to this privacy policy, we may notify you
            either by prominently posting a notice of such changes or by
            directly sending you a notification. We encourage you to review this
            privacy policy frequently to be informed of how we are protecting
            your information.
          </span>
        </div>
      </div>
    );
  };

  const getPrivacyQuestion10Div = () => {
    return (
      <div className="kt-privacypolicy-questionanswer-div" id="policycontact">
        <div className="kt-privacypolicy-question-div">
          10. How can you contact us about this policy?
        </div>
        <div className="kt-privacypolicy-answer-div">
          <span>
            If you have questions or comments about this policy, you may email
            us at privacy@dhansolutions.com or by post to: <br />
            Dhan AI Inc.
            <br />
            433 PLAZA REAL
            <br /> SUITE 275
            <br /> Boca Raton FL 33432
          </span>
        </div>
      </div>
    );
  };

  const getPrivacyPolicyQuestionsDiv = () => {
    //this is bad but cant help for now
    return (
      <>
        <div className="kt-privacypolicy-divider" />
        {getPrivacyQuestion1Div()}
        <div className="kt-privacypolicy-divider" />
        {getPrivacyQuestion2Div()}
        <div className="kt-privacypolicy-divider" />
        {getPrivacyQuestion3Div()}
        <div className="kt-privacypolicy-divider" />
        {getPrivacyQuestion4Div()}
        <div className="kt-privacypolicy-divider" />
        {getPrivacyQuestion5Div()}
        <div className="kt-privacypolicy-divider" />
        {getPrivacyQuestion6Div()}
        <div className="kt-privacypolicy-divider" />
        {getPrivacyQuestion7Div()}
        <div className="kt-privacypolicy-divider" />
        {getPrivacyQuestion8Div()}
        <div className="kt-privacypolicy-divider" />
        {getPrivacyQuestion9Div()}
        <div className="kt-privacypolicy-divider" />
        {getPrivacyQuestion10Div()}
      </>
    );
  };

  return (
    <PageComponent>
      <div className="kt-background-grey-main-row">
        <div className="kt-privacypolicy-maindiv">
          <div className="kt-privacypolicy-header-div">
            <span>Privacy Policy</span>
          </div>
          <div className="kt-privacypolicy-intro-div">
            {getIntroductionText()}
          </div>
          <div className="kt-privacypolicy-toc-div">
            {getPrivacyPolicyToc()}
          </div>
          <div className="kt-privacypolicy-questions-div">
            {getPrivacyPolicyQuestionsDiv()}
          </div>
        </div>
        <div className="kt-background-white-main-row">
          <div className="kt-row-abstract">{getContactUsFormSection()}</div>
        </div>
      </div>
    </PageComponent>
  );
}
