import React, { useMemo } from "react";
import {Button} from "@material-ui/core";
import ImageSection from "../../sections/ImageSection";
import { toAbsoluteUrl } from "../../../../_metronic/utils/utils";

export default function TrustedHealthCareSection() {
  const getTrustedHealthCareTextComponent = () => {
    return (
      <div className="kt-trusted-health-care-section-text">
        <div className="kt-trusted-health-care-section-header">
          Are you leveraging all your data assets? 
        </div>
        <div className="kt-trusted-health-care-section-description">
          <span>
            Leverage data as a strategic asset to improve your 
            decisions and build great products. 
          </span>
        </div>
      </div>
    );
  };

  const getTrustedHealthCareImageComponent = () => {
    return (
      <div className="kt-trusted-health-care-section-image">
        <img
          src={toAbsoluteUrl("/media/files/nlp-specialdiv-extract.svg")}
          alt="Conversational AI solutions"
        />
      </div>
    );
  };

  return (
    <ImageSection css_classname="kt-ai-sol-main-flex kt-section-height-400 kt-section-padding-top-100 kt-section-padding-bottom-100">
      {getTrustedHealthCareTextComponent()}
      {getTrustedHealthCareImageComponent()}
    </ImageSection>
  );
}
