import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest } from "redux-saga/effects";
import { getUserByToken, logOut } from "../../crud/auth.crud";
import { fetchPowerBiData } from "../../utils/powerbi.helper";
import * as routerHelpers from "../../router/RouterHelpers";

export const actionTypes = {
  Login: "[Login] Action",
  Logout: "[Logout] Action",
  Register: "[Register] Action",
  UserRequested: "[Request User] Action",
  UserLoaded: "[Load User] Auth API",
  SetAllowedRoutes: "[Set Routes] Roll Based",
  SetSwitcherItems: "[Set Switcher] Roll Based",
  LogoutRequested: "[Request Logout] Action",
  SetPowerBI: "[Set PowerBI] Action",
  PowerBIRequested: "[Request PowerBI] Action",
  SetSession: "[Set Session] Action",
};

const initialAuthState = {
  user: undefined,
  authToken: undefined,
  allowedRoutes: [],
  switcherItems: [],
  powerBI: [],
  isSessionActive: undefined,
};

export const reducer = persistReducer(
  {
    storage,
    key: "demo2-auth",
    whitelist: [
      "user",
      "authToken",
      "allowedRoutes",
      "switcherItems",
      "powerBI",
      "isSessionActive",
    ],
  },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.Login: {
        const { authToken, user } = action.payload;

        return { authToken, user, isSessionActive: true };
      }

      case actionTypes.Register: {
        const { authToken } = action.payload;

        return { authToken, user: undefined };
      }

      case actionTypes.Logout: {
        return initialAuthState;
      }

      case actionTypes.UserLoaded: {
        const { user } = action.payload;

        return { ...state, user };
      }
      case actionTypes.SetAllowedRoutes: {
        const { routes } = action.payload;

        return { ...state, allowedRoutes: routes };
      }
      case actionTypes.SetSwitcherItems: {
        const { items } = action.payload;

        return { ...state, switcherItems: items };
      }
      case actionTypes.SetPowerBI: {
        const { data } = action.payload;
        return { ...state, powerBI: data };
      }
      case actionTypes.SetSession: {
        return { ...state, isSessionActive: false };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  login: (authToken, user) => ({
    type: actionTypes.Login,
    payload: { authToken, user },
  }),
  register: (authToken) => ({
    type: actionTypes.Register,
    payload: { authToken },
  }),
  logout: (token) => ({ type: actionTypes.Logout, token }),
  requestUser: () => ({
    type: actionTypes.UserRequested,
  }),
  fulfillUser: (user) => ({ type: actionTypes.UserLoaded, payload: { user } }),
  setRoutes: (routes) => ({
    type: actionTypes.SetAllowedRoutes,
    payload: { routes },
  }),
  setSwitcher: (items) => ({
    type: actionTypes.SetSwitcherItems,
    payload: { items },
  }),
  requestPowerBI: (productID, token) => ({
    type: actionTypes.PowerBIRequested,
    payload: { productID, token },
  }),
  setPowerBI: (data) => ({
    type: actionTypes.SetPowerBI,
    payload: { data },
  }),
  setSession: () => ({
    type: actionTypes.SetSession,
  }),
};

export function* saga() {
  yield takeLatest(actionTypes.Logout, function* logoutRequested(action) {
    yield logOut(action.token);
  });

  yield takeLatest(actionTypes.Register, function* registerSaga() {
    yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.UserRequested, function* userRequested() {
    yield put(actions.fulfillUser());
  });
  yield takeLatest(actionTypes.PowerBIRequested, function* powerBIRequested(
    action
  ) {
    const data = yield fetchPowerBiData(
      action.payload.productID,
      action.payload.token
    );
    if (data.status) {
      yield put(actions.setSession());
    } else {
      yield put(actions.setPowerBI(data));
    }
    // yield put(actions.setPowerBI(data));
  });
}
