import React, { useMemo } from "react";
import {Button} from "@material-ui/core";
import ImageSection from "../../sections/ImageSection";
import { toAbsoluteUrl } from "../../../../_metronic/utils/utils";

export default function PredictiveAnalyticsSection() {
  const getPredictiveAnalyticsTextComponent = () => {
    return (
      <div className="kt-predictive-section-text">
        <div className="kt-predictive-section-header">
          Data driven culture delivered for your enterprise 
        </div>
        <div className="kt-predictive-section-description">
          <span>
            Build next generation data and business analytics 
            solutions that can drive data driven innovation in your enterprise. 
          </span>
        </div>
      </div>
    );
  };

  const getPredictiveAnalyticsImageComponent = () => {
    return (
      <div className="kt-predictive-section-image">
        <img
          src={toAbsoluteUrl(
            "/media/files/aisolutions-predictive-analytics.svg"
          )}
          alt="Conversational AI solutions"
        />
      </div>
    );
  };

  return (
    <ImageSection css_classname="kt-ai-sol-predictive-flex kt-section-height-480 kt-section-padding-top-80 kt-section-padding-bottom-80">
      {getPredictiveAnalyticsImageComponent()}
      {getPredictiveAnalyticsTextComponent()}
    </ImageSection>
  );
}
