import React, { useMemo } from "react";
import {Button} from "@material-ui/core";
import ImageSection from "../../sections/ImageSection";
import { toAbsoluteUrl } from "../../../../_metronic/utils/utils";

export default function ValueBasedSection() {
  const getValueBasedTextComponent = () => {
    return (
      <div className="kt-valuebased-section-text">
        <div className="kt-valuebased-section-header">
          Delivering innovative analytics solutions 
        </div>
        <div className="kt-valuebased-section-description">
          <span>
            Our solutions thrive on providing answers to the toughest 
            business questions, pursue breakthrough ideas and uncover 
            new patterns through data and analytic solutions.
          </span>
        </div>
      </div>
    );
  };

  const getValueBasedImageComponent = () => {
    return (
      <div className="kt-valuebased-section-image">
        <img
          src={toAbsoluteUrl("/media/files/aisolutions-revenue-cycle.svg")}
          alt="Conversational AI solutions"
        />
      </div>
    );
  };

  return (
    <ImageSection css_classname="kt-ai-sol-value-based-flex kt-section-height-480 kt-section-padding-top-80 kt-section-padding-bottom-80">
      {getValueBasedTextComponent()}
      {getValueBasedImageComponent()}
    </ImageSection>
  );
}
