import React, { useMemo } from "react";
import {Button} from "@material-ui/core";
import ImageSection from "../../sections/ImageSection";
import { toAbsoluteUrl } from "../../../../_metronic/utils/utils";

export default function TrainedDialogSection() {
  const getTrainedDialogTextComponent = () => {
    return (
      <div className="kt-trained-section-text">
        <div className="kt-trained-section-header">
          Experts in latest technologies to build the right experiences
        </div>
        <div className="kt-trained-section-description">
          <span>
            We leverage modern JavaScript frameworks such as 
            React and Angular for frontend development. These 
            frameworks offer pre-written code for basic tasks 
            which allows our developers to focus on the details that matter and build great products. 
          </span>
        </div>
      </div>
    );
  };

  const getTrainedDialogImageComponent = () => {
    return (
      <div className="kt-trained-section-image">
        <img
          src={toAbsoluteUrl(
            "/media/files/conversation-build-ai.svg"
          )}
          alt="Conversational AI solutions"
        />
      </div>
    );
  };

  return (
    <ImageSection css_classname="kt-conv-healthcare-flex kt-section-height-480 kt-section-padding-top-80 kt-section-padding-bottom-80">
      {getTrainedDialogImageComponent()}
      {getTrainedDialogTextComponent()}
    </ImageSection>
  );
}
