import React, { useMemo } from "react";
import {Button} from "@material-ui/core";
import ImageSection from "../../sections/ImageSection";
import { toAbsoluteUrl } from "../../../../_metronic/utils/utils";

export default function DeployAnywhereSection() {
  const getDeployAnywhereTextComponent = () => {
    return (
      <div className="kt-deployanywhere-section-text">
        <div className="kt-deployanywhere-section-header">
          Focus on your customer! 
        </div>
        <div className="kt-deployanywhere-section-description">
          <span>
            The customer’s digital journey spans across multiple 
            applications including your mobile apps, web applications, 
            chat interfaces etc. We partner with you to build solutions 
            that seamlessly connect your applications and increase customer satisfaction. 
          </span>
        </div>
      </div>
    );
  };

  const getDeployAnywhereImageComponent = () => {
    return (
      <div className="kt-deployanywhere-section-image">
        <img
          src={toAbsoluteUrl("/media/files/apps-customer-focus.svg")}
          alt="Conversational AI solutions"
        />
      </div>
    );
  };

  return (
    <ImageSection css_classname="kt-conv-deploy-flex kt-section-height-480 kt-section-padding-top-80 kt-section-padding-bottom-80">
      {getDeployAnywhereTextComponent()}
      {getDeployAnywhereImageComponent()}
    </ImageSection>
  );
}
