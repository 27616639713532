import React from "react";
import {Button} from "@material-ui/core";
import ImageSection from "../../sections/ImageSection";
import { toAbsoluteUrl } from "../../../../_metronic/utils/utils";

export default function AppsTransformSection() {
  const getAppsTransformTextComponent = () => {
    return (
      <div className="kt-apps-transform-section-text">
        <div className="kt-apps-transform-section-header">
          Delivering value 
        </div>
        <div className="kt-apps-transform-section-description">
          <span>
            Our vision remains to deliver great value to your business 
            by contributing significantly to your technological advancements. 
            You focus on the right project we take care of your technological requirements. 
          </span>
        </div>
      </div>
    );
  };

  const getAppsTransformImageComponent = () => {
    return (
      <div className="kt-apps-transform-section-image">
        <img 
          src={toAbsoluteUrl("/media/files/nlp-specialdiv-integrate.svg")}
          alt="Transform your digital solutions"
        />
      </div>
    );
  };

  return (
    <ImageSection css_classname="kt-apps-transform-flex kt-section-height-480 kt-section-padding-top-80 kt-section-padding-bottom-80">
      {getAppsTransformImageComponent()}
      {getAppsTransformTextComponent()}
    </ImageSection>
  );
}
