/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/pages/auth/AuthPage`, `src/pages/home/HomePage`).
 */

import React, { Suspense, lazy } from "react";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { useLastLocation } from "react-router-last-location";
import HomePage from "../pages/home/HomePage";
import { ErrorPage404 } from "../pages/errors/ErrorPage404";
import { LayoutContextProvider, LayoutSplashScreen } from "../../_metronic";
import Layout from "../../_metronic/layout/Layout";
import * as routerHelpers from "../router/RouterHelpers";
import CookieConsentComponent from "../pages/cookiePolicy/CookieConsentComponent";
import AuthPage from "../pages/auth/AuthPage";

export const Routes = withRouter(({ history }) => {
  const lastLocation = useLastLocation();
  const isAuthorized = JSON.parse(localStorage.getItem("loginInfo")) || null;
  routerHelpers.saveLastLocation(lastLocation);
  const { menuConfig, userLastLocation } = useSelector(
    ({ urls, builder: { menuConfig } }) => ({
      menuConfig,
      userLastLocation: routerHelpers.getLastLocation(),
    }),
    shallowEqual
  );

  return (
    /* Create `LayoutContext` from current `history` and `menuConfig`. */
    <LayoutContextProvider history={history} menuConfig={menuConfig}>
      <CookieConsentComponent />
      <Switch>
        <Layout>
          <HomePage userLastLocation={userLastLocation} />
        </Layout>
        <Redirect to="/error" />
      </Switch>
    </LayoutContextProvider>
  );
});
