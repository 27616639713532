import React, { useMemo } from "react";
import DigitalTransformationSectionComponent from "./sections/DigitalTransformationSection";
import MobileandWebSectionComponent from "./sections/MobileandWebSection";
import CloudSectionComponent from "./sections/CloudSection";
import DataSolutionsSectionComponent from "./sections/DataSolutionsSection";
import ContactFormSection from "../sections/ContactFormSection";

export default function LandingPageComponent() {
  const getDigitalTransformationSolutionsSection = () => {
    return <DigitalTransformationSectionComponent />;
  };

  const getMobileandWebSection = () => {
    return <MobileandWebSectionComponent />;
  };

  const getCloudSection = () => {
    return <CloudSectionComponent />;
  };

  const getDatasSolutionSection = () => {
    return <DataSolutionsSectionComponent />;
  };

  const getContactUsFormSection = () => {
    return <ContactFormSection />;
  };

  return (
    <>
      <div className="kt-landing">
        <div className="kt-background-white-main-row">
          <div className="kt-row-abstract">{getDigitalTransformationSolutionsSection()}</div>
        </div>
        <div className='kt-header-bottom' />
        <div className="kt-background-grey-main-row">
          <div className="kt-row-abstract">
            {getMobileandWebSection()}
          </div>
        </div>

        <div className="kt-background-white-main-row">
          <div className="kt-row-abstract">{getCloudSection()}</div>
        </div>

        <div className="kt-background-white-main-row">
          <div className="kt-row-abstract">{getDatasSolutionSection()}</div>
        </div>

        <div className="kt-background-white-main-row">
          <div className="kt-row-abstract">{getContactUsFormSection()}</div>
        </div>
      </div>
    </>
  );
}
