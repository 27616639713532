import React from "react";
import { Helmet } from "react-helmet";
export default function CustomHelmet(HelmetData, Component) {
  return () => {
    return (
      <>
        <Helmet>
          <title>{HelmetData.title}</title>
          <meta name="description" content={HelmetData.meta_description} />
        </Helmet>
        <Component />
      </>
    );
  };
}
