import React, { Suspense, useEffect, lazy } from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import LandingPageComponent from "./LandingPageComponent";
import WebandMobilepageComponent from "../WebandMobile/WebandMobilepageComponent";
import CloudComponent from "../Cloud/CloudComponent";
import DataPageComponent from "../DataSolutions/DataPageComponent";
import ApproachPageComponent from "../approach/ApproachPageComponent";
import PrivacyPolicyPageComponent from "../privacyPolicy/PrivacyPolicyPageComponent";
import CookiePolicyPageComponent from "../cookiePolicy/CookiePolicyPageComponent";
import { LayoutSplashScreen } from "../../../_metronic";
import { ErrorPage404 } from "../errors/ErrorPage404";
import { hideLayout, showLayout } from "../../utils/toggleLayout";
import CustomHelmet from "../../components/CustomHelmet/CustomHelmet";
import TitleMetaData from "../../utils/SeoConstants/title_meta.json";
export default function HomePage() {
  const LandingPageWithCustomHelmet = CustomHelmet(
    TitleMetaData.LandingPage,
    LandingPageComponent
  );
  const WebandMobilepageWithCustomHelmet = CustomHelmet(
    TitleMetaData.webandmobile,
    WebandMobilepageComponent
  );
  const CloudpageWithCustomHelmet = CustomHelmet(
    TitleMetaData.Cloud,
    CloudComponent
  );
  const DataPageWithCustomHelmet = CustomHelmet(
    TitleMetaData.DataSolutions,
    DataPageComponent
  );
  const ApproachPageWithCustomHelmet = CustomHelmet(
    TitleMetaData.Approach,
    ApproachPageComponent
  );
  let location = useLocation();

  useEffect(() => {
    if (
      location.pathname.indexOf("auth") > -1 ||
      location.pathname.indexOf("error") > -1 ||
      location.pathname.indexOf("user") > -1
    ) {
      hideLayout(location.pathname);
    } else {
      showLayout();
    }
  }, [location.pathname]);

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        <Route
          exact
          path="/webandmobile"
          component={WebandMobilepageWithCustomHelmet}
        />
        <Route exact path="/cloud" component={CloudpageWithCustomHelmet} />
        <Route
          exact
          path="/datasolutions"
          component={DataPageWithCustomHelmet}
        />
        <Route
          exact
          path="/approach"
          component={ApproachPageWithCustomHelmet}
        />
        <Route
          exact
          path="/privacy-policy"
          component={PrivacyPolicyPageComponent}
        />
        <Route
          exact
          path="/cookies-tracking-notice"
          component={CookiePolicyPageComponent}
        />
        <Route exact path="/" component={LandingPageWithCustomHelmet} />
        <Route exact path="/error" component={ErrorPage404} />
        <Redirect to="/error" />
      </Switch>
    </Suspense>
  );
}
