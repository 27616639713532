export function hideLayout(path) {
  if (path.indexOf("error") > -1) {
    setTimeout(() => {
      document.querySelector(".kt-header__top").classList.add("hide-layout");
      document.querySelector(".kt-footer").classList.add("hide-layout");
    })
  }
  else {
    document.querySelector(".kt-header__top").classList.add("hide-layout");
    document.querySelector(".kt-footer").classList.add("hide-layout");
  }
}

export function showLayout() {
  document.querySelector(".kt-header__top").classList.remove("hide-layout");
  document.querySelector(".kt-footer").classList.remove("hide-layout");
}
