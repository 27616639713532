import React, { useMemo } from "react";
import {Button} from "react-bootstrap";
import TextSection from "../../sections/TextSection";

export default function DigitalTransformationSection() {
  const getHeader = () => {
    return (
      <div className="kt-aisolutions-header">
        <span>Digital Transformation Solutions for your Enterprise</span>
      </div>
    );
  };
  const getDescription = () => {
    return (
      <div className="kt-aisolutions-description">
        <span>
          We partner with your enterprise to build digital
          solutions by leveraging latest technologies to solve your unique problems. 
        </span>
      </div>
    );
  };
  const getButton = () => {
    return (
      <div className="kt-talk-to-us-buttondiv">
        <a href="#talk-to-us-form-div">
          <Button variant="contained" className="kt-talk-to-us-button">
            Talk to Us
            <img src='/media/misc/arrow-next.svg' alt='arrow' />
          </Button>
        </a>
      </div>
    );
  };
  return (
    <TextSection css_classname="kt-section-height-670 kt-section-padding-top-145 kt-section-padding-bottom-145">
      {getHeader()}
      {getDescription()}
      {getButton()}
    </TextSection>
  );
}
