import React from "react";
import {Button} from "@material-ui/core";
import ImageSection from "../../sections/ImageSection";
import { toAbsoluteUrl } from "../../../../_metronic/utils/utils";

export default function AppsMainSection() {
  const getAppsMainSectionTextComponent = () => {
    return (
      <div className="kt-apps-main-section-text">
        <div className="kt-apps-main-section-header">
          Silicon Valley Best Practices delivered at your doorstep! 
        </div>
        <div className="kt-apps-main-section-description">
          <span>
            Our team of engineers have combined decades of experience 
            in building products at world leading companies like 
            Microsoft, Facebook, Oracle and several startups etc. 
            We bring the best technologies and engineering practices to develop your solutions.
          </span>
        </div>
      </div>
    );
  };

  const getAppsMainSectionImageComponent = () => {
    return (
      <div className="kt-apps-main-section-image">
        <img
          src={toAbsoluteUrl("/media/files/apps-engineering-excellence.svg")}
          alt="Application Development"
        />
      </div>
    );
  };

  return (
    <ImageSection css_classname="kt-apps-main-flex kt-section-height-600 kt-section-padding-top-110 kt-section-padding-bottom-110">
      {getAppsMainSectionTextComponent()}
      {getAppsMainSectionImageComponent()}
    </ImageSection>
  );
}
