/**
 * Entry application component used to compose providers and render Routes.
 *
 * Note: Because of
 */

import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { LastLocationProvider } from "react-router-last-location";
import { Routes } from "./website/router/Routes";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { getAppInsights } from "./TelemetryService";
import TelemetryProvider from "./telemetry-provider";
import { I18nProvider, LayoutSplashScreen, ThemeProvider } from "./_metronic";
import { APP_INSIGHTS_InstrumentationKey } from "./website/utils/constants";
import GA from "./website/utils/GoogleAnalytics";

export default function App({ store, persistor, basename }) {
  let appInsights = null;
  return (
    /* Provide Redux store */
    <Provider store={store} loading={<LayoutSplashScreen />}>
      {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
      <PersistGate persistor={persistor}>
        {/* Add high level `Suspense` in case if was not handled inside the React tree. */}
        <React.Suspense fallback={<LayoutSplashScreen />}>
          {/* Override `basename` (e.g: `homepage` in `package.json`) */}
          <BrowserRouter basename={basename}>
            {GA.init() && (
              <GA.RouteTracker />
            ) /* to initiate the Google Analytics tracker, runs only on production for now */}
            <TelemetryProvider
              instrumentationKey={APP_INSIGHTS_InstrumentationKey}
              after={() => {
                appInsights = getAppInsights();
              }}
            >
              {/*This library only returns the location that has been active before the recent location change in the current window lifetime.*/}
              <LastLocationProvider>
                {/* Provide Metronic theme overrides. */}
                <ThemeProvider>
                  {/* Provide `react-intl` context synchronized with Redux state.  */}
                  <I18nProvider>
                    {/* Render routes with provided `Layout`. */}
                    <Routes />
                  </I18nProvider>
                </ThemeProvider>
              </LastLocationProvider>
            </TelemetryProvider>
          </BrowserRouter>
        </React.Suspense>
      </PersistGate>
    </Provider>
  );
}
