import React from "react";
import {Button} from "@material-ui/core";
import ImageSection from "../../sections/ImageSection";
import { toAbsoluteUrl } from "../../../../_metronic/utils/utils";

export default function AppsEngExcelSection() {
  const getAppsEngExcelTextComponent = () => {
    return (
      <div className="kt-eng-excel-section-text">
        <div className="kt-eng-excel-section-header">
          Focus on the customer! 
        </div>
        <div className="kt-eng-excel-section-description">
          <span>
            The customer’s digital journey spans across multiple 
            applications including your mobile apps, web applications, 
            chat interfaces etc. We partner with you to build solutions 
            that seamlessly connect your applications and increase customer satisfaction 
          </span>
        </div>
      </div>
    );
  };

  const getAppsEngExcelImageComponent = () => {
    return (
      <div className="kt-eng-excel-section-image">
        <img
          src={toAbsoluteUrl("/media/files/apps-partner.svg")}
          alt="Engineering Excellence"
        />
      </div>
    );
  };

  return (
    <ImageSection css_classname="kt-apps-excellence-flex kt-section-height-480 kt-section-padding-top-80 kt-section-padding-bottom-80">
      {getAppsEngExcelTextComponent()}
      {getAppsEngExcelImageComponent()}
    </ImageSection>
  );
}
