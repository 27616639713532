import * as React from "react";
import Cookies from "js-cookie";
import {Snackbar} from "@material-ui/core";
import {Button} from "@material-ui/core";

export default class CookieConsentComponent extends React.Component {
  defaultProps = {
    componentType: "Snackbar",
    cookieValue: "1",
    cookieName: "dhansolutions-cookie-consent",
    expires: 365,
    hideOnAccept: true,
    snackbarAnchor: { horizontal: "center", vertical: "bottom" },
    children: null,
    message: "I love cookies!",
    title: null,
    acceptButtonLabel: "Accept",
    actions: null
  };

  constructor(props) {
    super(props);
    this.state = {
      visible: false
    };
  }

  getCookieName() {
    if (this.props.cookieName) {
      return this.props.cookieName;
    }
    return this.defaultProps.cookieName;
  }

  getCookieValue() {
    if (this.props.cookieValue) {
      return this.props.cookieValue;
    }
    return this.defaultProps.cookieValue;
  }

  getExpires() {
    if (this.props.expires) {
      return this.props.expires;
    }
    return this.defaultProps.expires;
  }

  getHideOnAccept() {
    if (this.props.hideOnAccept) {
      return this.props.hideOnAccept;
    }
    return this.defaultProps.hideOnAccept;
  }

  getMessage() {
    if (this.props.message) {
      return this.props.message;
    }
    return this.defaultProps.message;
  }

  getAcceptButtonLabel() {
    if (this.props.acceptButtonLabel) {
      return this.props.acceptButtonLabel;
    }
    return this.defaultProps.acceptButtonLabel;
  }

  componentDidMount() {
    const cookieName = this.getCookieName();

    if (Cookies.get(cookieName) === undefined) {
      this.setState({ visible: true });
    }
  }

  /**
   * Set a persistent cookie
   */
  handleAccept = () => {
    const cookieName = this.getCookieName();
    const cookieValue = this.getCookieValue();
    const expires = this.getExpires();
    const hideOnAccept = this.getHideOnAccept();

    Cookies.set(cookieName, cookieValue, { expires });

    if (hideOnAccept) {
      this.setState({ visible: false });
    }
  };

  render() {
    const acceptButtonLabel = this.getAcceptButtonLabel();
    const actions = null;
    const snackbarAnchor = { horizontal: "center", vertical: "bottom" };

    return (
      <Snackbar
        anchorOrigin={snackbarAnchor}
        open={this.state.visible}
        message={
          <>
            <span id="message-id">
              This site accepts cookies. Please check the{" "}
              <a href="/cookies-tracking-notice" target="_blank">
                cookie policy
              </a>
              .
            </span>
          </>
        }
        action={[
          ...React.Children.toArray(actions),
          <Button
            key="accept"
            color="secondary"
            size="small"
            onClick={this.handleAccept}
          >
            {acceptButtonLabel}
          </Button>
        ]}
      />
    );
  }
}
