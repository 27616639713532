import React, { useMemo } from "react";
import {Button} from "react-bootstrap";
import ImageSection from "../../sections/ImageSection";
import { toAbsoluteUrl } from "../../../../_metronic/utils/utils";

export default function DataSolutionsSection() {
  const getCustomAITextComponent = () => {
    return (
      <div className="kt-customai-section-text">
        <div className="kt-customai-section-header">
          Are you leveraging all your data assets? 
        </div>
        <div className="kt-customai-section-description">
          <span>
            Leverage data as a strategic asset in improving 
            your decisions, building analytical solutions and great products. 
          </span>
        </div>
        <div className="kt-learn-more-buttondiv">
          <Button
            variant="contained"
            color="primary"
            className="kt-learn-more-button"
            onClick={e => {
              e.preventDefault();
              window.location.href = "/datasolutions";
            }}
          >
            Learn More
          </Button>
        </div>
      </div>
    );
  };

  const getCustomAIImageComponent = () => {
    return (
      <div className="kt-customai-section-image">
        <img
          src={toAbsoluteUrl("/media/files/aisolutions-real-world.svg")}
          alt="Custom AI solutions"
        />
      </div>
    );
  };

  return (
    <ImageSection css_classname="kt-custom-ai-flex kt-section-height-500 kt-section-padding-top-40 kt-section-padding-bottom-40">
      {getCustomAITextComponent()}
      {getCustomAIImageComponent()}
    </ImageSection>
  );
}
