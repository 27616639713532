import React, { useMemo } from "react";
import {Button} from "@material-ui/core";
import ImageSection from "../../sections/ImageSection";
import { toAbsoluteUrl } from "../../../../_metronic/utils/utils";

export default function BuildAIConversationSection() {
  const getBuildConversationAITextComponent = () => {
    return (
      <div className="kt-conversationai-solution-section-text">
        <div className="kt-conversationai-solution-section-header">
          Application Experience Matters 
        </div>
        <div className="kt-conversationai-solution-section-description">
          <span>
            Your customers deserve the best web and mobile applications
            to interact with your products and solutions. Let us help you 
            provide the best application experience to your customers! 
          </span>
        </div>
      </div>
    );
  };

  const getBuldConversationAIImageComponent = () => {
    return (
      <div className="kt-conversationai-solution-section-image">
        <img
          src={toAbsoluteUrl("/media/files/conversation-health-care-trained-ai.svg")}
          alt="Conversational AI solutions"
        />
      </div>
    );
  };

  return (
    <ImageSection css_classname="kt-conv-ai-flex kt-section-height-600 kt-section-padding-top-110 kt-section-padding-bottom-110">
      {getBuildConversationAITextComponent()}
      {getBuldConversationAIImageComponent()}
    </ImageSection>
  );
}
