import React, { useState, useEffect } from "react";
import {Form} from "react-bootstrap";
import {Button} from "react-bootstrap";
import initLayoutConfig from "../../../_metronic/layout/LayoutConfig";
import TextSection from "./TextSection";

export default function ContactFormSection(props) {
  const [fullName, setFullName] = useState("");
  const [companyEmail, setCompanyEmail] = useState("");
  const [companyPhone, setCompanyPhone] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [helpText, setHelpText] = useState("");

  const getBodyJson = () => {
    return {
      fullName: fullName,
      companyEmail: companyEmail,
      companyPhone: companyPhone,
      jobTitle: jobTitle,
      helpYou: helpText
    };
  };

  const getHost = () => {
    const act_url = window.location.href;
    if (act_url.includes("localhost"))
      return act_url.substring(0, act_url.indexOf("/", 8));
    else return initLayoutConfig.production_backend_url;
  };

  const getRESTURL = () => {
    const host = getHost();
    return host + "/api/ner/dhansolutions/contactdata";
  };

  const sendTosheets = () => {
    const url = getRESTURL();
    console.log("rest url for submission of data ", url);
    const body_json = getBodyJson();

    fetch(url, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        "Content-Type": "application/json"
      },
      redirect: "follow", // manual, *follow, error
      body: JSON.stringify(body_json) // body data type must match "Content-Type" header
    }).then(response => {
      console.log("response :::", response);
    });
  };

  const handleSubmit = event => {
    event.preventDefault();
    event.stopPropagation();
    sendTosheets();
  };

  return (
    <TextSection css_classname="kt-contact-form-custom-padding kt-section-height-736 kt-section-padding-top-80">
      <div className="kt-contactform-header" id="talk-to-us-form-div">
        <span>Don’t let your customers wait! Contact Us</span>
      </div>
      <div className="kt-contactform-actform">
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formFullName">
            <Form.Control
              type="text"
              placeholder="Full Name"
              className="kt-contactform-textinput"
              onChange={event => setFullName(event.target.value)}
            />
          </Form.Group>

          <Form.Group controlId="formCompanyEmail">
            <Form.Control
              type="email"
              placeholder="Company email"
              className="kt-contactform-textinput"
              onChange={event => setCompanyEmail(event.target.value)}
            />
          </Form.Group>

          <Form.Group controlId="formCompanyPhone">
            <Form.Control
              type="text"
              placeholder="Company Phone"
              className="kt-contactform-textinput"
              onChange={event => setCompanyPhone(event.target.value)}
            />
          </Form.Group>

          <Form.Group controlId="formJobTitle">
            <Form.Control
              type="text"
              placeholder="Job Title"
              className="kt-contactform-textinput"
              onChange={event => setJobTitle(event.target.value)}
            />
          </Form.Group>

          <Form.Group controlId="formHelpYou">
            <Form.Control
              type="text"
              placeholder="How can we help you ..."
              className="kt-contactform-textinput"
              onChange={event => setHelpText(event.target.value)}
            />
          </Form.Group>

          <Button
            variant="primary"
            type="submit"
            className="kt-contactform-submitbutton"
          >
            Talk to Us
            <img src='/media/misc/arrow-next.svg' alt='arrow' />
          </Button>
        </Form>
      </div>
      <div className="kt-contactform-footer">
        Our team consists of Silicon Valley engineers with multiple patents.
        Talk to us on how we can help you
      </div>
    </TextSection>
  );
}
