import { DATASET_ID, REPORT_ID, WORKSPACE_ID, URL } from "./constants";

export function delayedLogout(time, callBack) {
  setTimeout(() => {
    callBack();
  }, time);
}

function handleErrors(response) {
  if (!response.ok) {
    if (response.status >= 500) {
      let err = new Error("ServerError");
      err.status = response.status;
      throw err;
    }
    if (response.status <= 501) {
      let err = new Error("ApplicationError");
      err.status = response.status;
      throw err;
    }
  } else {
    return response;
  }
}

export const fetchPowerReport = (report_id, workspace_id) => {
  return fetch(
    `${URL}/powerbi/embedreport?reportid=${report_id}&workspaceid=${workspace_id}`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  )
    .then(handleErrors)
    .then((res) => {
      return res.json();
    })
    .catch((err) => err);
};
export const fetchPowerBiData = (productID, token) => {
  return fetch(`${URL}powerbi/metadata?product_id=${productID}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    },
  })
    .then(handleErrors)
    .then((res) => {
      return res.json();
    })
    .then((data) => {
      const payload = data.map((item) => {
        const {
          dbid,
          product_id,
          product_name,
          report_id,
          workspace_id,
          dataset_id,
          category,
          report_name,
        } = item;
        return {
          id: dbid,
          product_id,
          product_name,
          report_id,
          workspace_id,
          dataset_id,
          category,
          report_name,
        };
      });
      return payload;
    })
    .catch((err) => err);
};
