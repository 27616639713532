/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";
import objectPath from "object-path";
import { connect } from "react-redux";
import { toAbsoluteUrl } from "../../utils/utils";
import * as builder from "../../ducks/builder";

class Footer extends React.Component {
  render() {
    const today = new Date().getFullYear();
    const {
      footerSelfLayoutIsExtended,
      footerClasses,
      footerContainerClasses,
    } = this.props;
    return (
      <div
        className={`kt-footer ${footerClasses} kt-grid__item`}
        id="kt_footer"
      >
        {footerSelfLayoutIsExtended && (
          <div className="kt-footer__top">
            <div className={`kt-container ${footerContainerClasses}`}>
              <div className="row kt-dhan-footer-top">
                <div className="col-lg-3">
                  <div className="kt-footer__section">
                    <div className="kt-footer__title">PRODUCT</div>
                    <div className="kt-footer__content">
                      <div className="kt-footer__nav">
                        <div className="kt-footer__nav-section">
                          <a href="/webandmobile">Mobile & Web</a>
                          <a href="/cloud">Cloud</a>
                          <a href="/datasolutions">Data Solutions</a>
                          <a href="/approach">Approach</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3">
                  <div className="kt-footer__section">
                    <div className="kt-footer__title">COMPANY</div>
                    <div className="kt-footer__content">
                      <span>2100 Geng Rd, Suite 210</span>
                      <br />
                      <span> Palo Alto CA 94303</span>
                    </div>
                    <div className="kt-footer__content">
                      <span>433 Plaza Real Suite 275</span>
                      <br />
                      <span> Boca Raton FL 33432</span>
                    </div>
                    <div className="kt-footer__content">
                      <span>Awfis, 3rd Floor, Sreshta Marvel,</span>
                      <br />
                      <span>
                        {" "}
                        P Janardhan Reddy Nagar, Gachibowli, Hyderabad,
                      </span>
                      <br />
                      <span> Telangana 500081 India</span>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3">
                  <div className="kt-footer__section">
                    <div className="kt-footer__title">CONTACT</div>
                    <div className="kt-footer__content">
                      <span>
                        <a href="mailto:contact@dhan.ai">contact@dhan.ai</a>
                      </span>
                      <br />
                      <span>
                        <a href="tel:(407) 349-3755">(407) 349-3755</a>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="kt-footer__bottom">
          <div className={`kt-container ${footerContainerClasses}`}>
            <div className="kt-footer__wrapper">
              <div className="kt-footer__logo">
                <div className="kt-footer__copyright">
                  <span> Dhan AI Inc. &nbsp;&copy;&nbsp; {today}</span>
                </div>
              </div>
              <div className="kt-footer__menu">
                <a href="/cookies-tracking-notice/" target="_blank">
                  Cookie Policy
                </a>
                <a href="/privacy-policy/" target="_blank">
                  Privacy Policy
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store) => ({
  footerSelfLayoutIsExtended:
    objectPath.get(store.builder.layoutConfig, "footer.self.layout") ===
    "extended",
  footerClasses: builder.selectors.getClasses(store, {
    path: "footer",
    toString: true,
  }),
  footerContainerClasses: builder.selectors.getClasses(store, {
    path: "footer_container",
    toString: true,
  }),
});

export default connect(mapStateToProps)(Footer);
