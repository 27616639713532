/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { Dropdown } from "react-bootstrap";
import { connect } from "react-redux";
import { toAbsoluteUrl } from "../../../_metronic";
import HeaderDropdownToggle from "../content/CustomDropdowns/HeaderDropdownToggle";
import { logOut } from "../../crud/auth.crud";
import * as auth from "../../store/ducks/auth.duck";
import { Link, withRouter } from "react-router-dom";
class UserProfile extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    // const user = JSON.parse(localStorage.getItem("loginInfo")) || null;
    const { showHi, showAvatar, showBadge, user, authToken } = this.props;

    return user ? (
      <Dropdown
        className="kt-header__topbar-item kt-header__topbar-item--user"
        drop="down"
        alignRight
      >
        <Dropdown.Toggle
          as={HeaderDropdownToggle}
          id="dropdown-toggle-user-profile"
        >
          <div className="kt-header__topbar-user">
            {/* {showHi && (
              <span className="kt-header__topbar-welcome kt-hidden-mobile">
                Hi,
              </span>
            )}

            {showHi && (
              <span className="kt-header__topbar-username kt-hidden-mobile">
                {user.user.name}
              </span>
            )} */}

            {showAvatar && (
              <img
                alt="Pic"
                src={toAbsoluteUrl("/media/users/mystery-man.png")}
              />
            )}

            {showBadge && (
              <span className="kt-badge kt-badge--username kt-badge--unified-success kt-badge--lg kt-badge--rounded kt-badge--bold">
                {/* TODO: Should get from currentUser */}
                John Doe
              </span>
            )}
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu className="dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl">
          {/** ClassName should be 'dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl' */}
          <div
            className="kt-user-card kt-user-card--skin-dark kt-notification-item-padding-x"
            style={{
              backgroundImage: `url(${toAbsoluteUrl("/media/misc/bg-1.jpg")})`,
            }}
          >
            <div className="kt-user-card__avatar">
              <img
                alt="Pic"
                className="kt-hidden"
                src={toAbsoluteUrl("/media/users/mystery-man.png")}
              />
              <span className="kt-badge kt-badge--lg kt-badge--rounded kt-badge--bold kt-font-success">
                {user.name.slice(0, 1)}
              </span>
            </div>
            <div className="kt-user-card__name">{user.name}</div>
          </div>
          <div className="kt-notification">
            <a className="kt-notification__item">
              <div className="kt-notification__item-icon">
                <i className="flaticon2-calendar-3 kt-font-success" />
              </div>
              <Dropdown.Item className="dropdown-zero-padding">
                <Link
                  className="kt-notification__item-details"
                  to="/app/profile/details"
                >
                  <div className="kt-notification__item-title kt-font-bold">
                    My Profile
                  </div>
                  <div className="kt-notification__item-time">
                    Account settings and more
                  </div>
                </Link>
              </Dropdown.Item>
            </a>
            <div className="kt-notification__custom">
              <button
                onClick={() => {
                  this.props.logout(authToken);
                  this.props.history.push("/");
                }}
                className="btn btn-label-brand btn-sm btn-bold"
              >
                Sign Out
              </button>
            </div>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    ) : null;
  }
}

const mapStateToProps = ({ auth: { user, authToken } }) => ({
  user,
  authToken,
});

export default connect(mapStateToProps, auth.actions)(withRouter(UserProfile));
// export default UserProfile;
