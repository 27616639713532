import React, { useMemo } from "react";
import PageComponent from "../AbstractPage";
import TrustedHealthCareSection from "./sections/TrustedHealthCareSection";
import PredictiveAnalyticsSection from "./sections/PredictiveAnalyticsSection";
import ValueBasedSection from "./sections/ValueBasedSection";
import ContactFormSection from "../sections/ContactFormSection";

export default function DataPageComponent() {
  const getTrustedHealthCareSection = () => {
    return <TrustedHealthCareSection />;
  };

  const getPredictiveSection = () => {
    return <PredictiveAnalyticsSection />;
  };

  const getValueBasedSection = () => {
    return <ValueBasedSection />;
  };

  const getContactUsFormSection = () => {
    return <ContactFormSection />;
  };

  return (
    <PageComponent>
      <div className="kt-conversationalai">
        <div className="kt-background-white-main-row">
          <div className="kt-row-abstract">{getTrustedHealthCareSection()}</div>
        </div>

        <div className="kt-background-grey-main-row">
          <div className="kt-row-abstract">{getPredictiveSection()}</div>
        </div>

        <div className="kt-background-white-main-row">
          <div className="kt-row-abstract">{getValueBasedSection()}</div>
        </div>
        <div className="kt-background-grey-main-row">
          <div className="kt-row-abstract">{getContactUsFormSection()}</div>
        </div>
      </div>
    </PageComponent>
  );
}
