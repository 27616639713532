import React, { useMemo } from "react";
import { Button } from "@material-ui/core";
import ImageSection from "../../sections/ImageSection";
import { toAbsoluteUrl } from "../../../../_metronic/utils/utils";

export default function NLPSection() {
  const getNLPTextComponent = () => {
    return (
      <div className="kt-nlp-section-text">
        <div className="kt-nlp-section-header">
          Cloud Transformation Services
        </div>
        <div className="kt-nlp-section-description">
          <span>
            We provide Cloud Strategy, Cloud Migration, Cloud Operations 
            and Devops implementation Services. We leverage industry best 
            practices to build cloud native applications. 
          </span>
        </div>
      </div>
    );
  };

  const getNLPImageComponent = () => {
    return (
      <div className="kt-nlp-section-image">
        <img
          src={toAbsoluteUrl("/media/files/aisolutions-trustedhealthcare.svg")}
          alt="Conversational AI solutions"
        />
      </div>
    );
  };

  return (
    <ImageSection css_classname="kt-nlp-main-flex kt-section-height-600 kt-section-padding-top-110 kt-section-padding-bottom-110">
      {getNLPTextComponent()}
      {getNLPImageComponent()}
    </ImageSection>
  );
}
