import React, { useMemo } from "react";
import {Button} from "react-bootstrap";
import ImageSection from "../../sections/ImageSection";
import { toAbsoluteUrl } from "../../../../_metronic/utils/utils";

export default function MobileandWebSection() {
  const getConversationAITextComponent = () => {
    return (
      <div className="kt-conversationai-section-text">
        <div className="kt-conversationai-section-header">
          Web and Mobile Experience Matters!  
        </div>
        <div className="kt-conversationai-section-description">
          <span>
            Your customers deserve the best web and mobile applications 
            to interact with your products and solutions. Let us help you 
            provide the best application experience to your customers! 
          </span>
        </div>
        <div className="kt-learn-more-buttondiv">
          <Button
            variant="contained"
            className="kt-learn-more-button"
            onClick={e => {
              e.preventDefault();
              window.location.href = "/webandmobile";
            }}
          >
            Learn More
          </Button>
        </div>
      </div>
    );
  };

  const getConversationAIImageComponent = () => {
    return (
      <div className="kt-conversationai-solution-section-image">
        <img
          src={toAbsoluteUrl("/media/files/apps-digital-solutions.svg")}
          alt="Conversational AI solutions"
        />
      </div>
    );
  };

  return (
    <ImageSection css_classname="kt-conv-ai-flex kt-section-height-720 kt-section-padding-top-104 kt-section-padding-bottom-104">
      {getConversationAITextComponent()}
      {getConversationAIImageComponent()}
    </ImageSection>
  );
}
