import React, { useMemo } from "react";
import PageComponent from "../AbstractPage";
import ApplicationExperienceSection from "./sections/ApplicationExperienceSection";
import TrainedDialogSection from "./sections/TrainedDialogSection";
import FocuscustomerSection from "./sections/FocuscustomerSection";
import ContactFormSection from "../sections/ContactFormSection";

export default function WebandMobilepageComponentComponent() {
  const getApplicationExperienceSection = () => {
    return <ApplicationExperienceSection />;
  };

  const getTrainedDialogueSection = () => {
    return <TrainedDialogSection />;
  };

  const getFocuscustomerSection = () => {
    return <FocuscustomerSection />;
  };

  const getContactUsFormSection = () => {
    return <ContactFormSection />;
  };



  return (
    <PageComponent>
      <div className="kt-conversationalai">
        <div className="kt-background-white-main-row">
          <div className="kt-row-abstract">
            {getApplicationExperienceSection()}
          </div>
        </div>

        <div className="kt-background-grey-main-row">
          <div className="kt-row-abstract">{getTrainedDialogueSection()}</div>
        </div>

        <div className="kt-background-white-main-row">
          <div className="kt-row-abstract">{getFocuscustomerSection()}</div>
        </div>

        <div className="kt-background-grey-main-row">
          <div className="kt-row-abstract">{getContactUsFormSection()}</div>
        </div>
      </div>
    </PageComponent>
  );
}
