import React, { useMemo } from "react";
import {Button} from "react-bootstrap";
import ImageSection from "../../sections/ImageSection";
import { toAbsoluteUrl } from "../../../../_metronic/utils/utils";

export default function CloudSection() {
  const getNLPTextComponent = () => {
    return (
      <div className="kt-nlp-section-text">
        <div className="kt-nlp-section-header">
          Leverage Cloud to build next generation applications 
        </div>
        <div className="kt-nlp-section-description">
          <span>
            Our certified cloud experts, can support you through 
            the entire cloud journey, from helping you develop your
            cloud migration strategy to assisting with your cloud migration to operations. 
          </span>
        </div>
        <div className="kt-learn-more-buttondiv">
          <Button
            variant="contained"
            color="primary"
            className="kt-learn-more-button"
            onClick={e => {
              e.preventDefault();
              window.location.href = "/cloud";
            }}
          >
            Learn More
          </Button>
        </div>
      </div>
    );
  };

  const getNLPImageComponent = () => {
    return (
      <div className="kt-nlp-section-image">
        <img
          src={toAbsoluteUrl("/media/files/conversation-deploy-anywhere.svg")}
          alt="NLP solutions"
        />
      </div>
    );
  };

  return (
    <ImageSection css_classname="kt-nlp-flex kt-section-height-720 kt-section-padding-top-104 kt-section-padding-bottom-104">
      {getNLPImageComponent()}
      {getNLPTextComponent()}
    </ImageSection>
  );
}
