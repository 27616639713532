import React from "react";
import {Button} from "@material-ui/core";
import ImageSection from "../../sections/ImageSection";
import { toAbsoluteUrl } from "../../../../_metronic/utils/utils";

export default function AppsCustomerFocusSection() {
  const getAppsCustomerFocusTextComponent = () => {
    return (
      <div className="kt-customer-focus-text">
        <div className="kt-customer-focus-header">
          Engineering Excellence 
        </div>
        <div className="kt-customer-focus-description">
          <span>
            All our projects start with the best industry practices 
            including unit tests, integration tests, automated cross 
            browser testing, CI/CD, code analysis, security design from the start.
            None of these are an after thought for us! 
          </span>
        </div>
      </div>
    );
  };

  const getAppsCustomerFocusImageComponent = () => {
    return (
      <div className="kt-customer-focus-image">
        <img
          src={toAbsoluteUrl(
            "/media/files/nlp-nlp.svg"
          )}
          alt="Customer Focus"
        />
      </div>
    );
  };

  return (
    <ImageSection css_classname="kt-apps-customer-focus-flex kt-section-height-480 kt-section-padding-top-80 kt-section-padding-bottom-80">
      {getAppsCustomerFocusImageComponent()}
      {getAppsCustomerFocusTextComponent()}
    </ImageSection>
  );
}
