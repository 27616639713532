import React, { useMemo } from "react";
import PageComponent from "../AbstractPage";
import ContactFormSection from "../sections/ContactFormSection";

export default function CookiePolicyPageComponent() {
  const getContactUsFormSection = () => {
    return <ContactFormSection />;
  };

  const getIntroductionText = () => {
    return (
      <>
        <span>
          <b>Dhan AI Inc.</b> and our third party partners, such as our
          advertising and analytics partners, use various technologies to
          collect information, such as cookies and web beacons.
        </span>
      </>
    );
  };

  const getPrivacyQuestion1Div = () => {
    return (
      <div
        className="kt-privacypolicy-questionanswer-div"
        id="informationcollect"
      >
        <div className="kt-privacypolicy-question-div">
          What types of technologies do we use?
        </div>
        <div className="kt-privacypolicy-answer-div">
          <span>
            We use cookies, web beacons and other technologies to improve and
            customize our products and websites (“the Services”) and your
            experience; to allow you to access and use the Services without
            re-entering your username or password; to understand usage of our
            Services and the interests of our customers; to determine whether an
            email has been opened and acted upon; and to present you with
            advertising relevant to your interests.
          </span>
        </div>
      </div>
    );
  };

  const getPrivacyQuestion2Div = () => {
    return (
      <div className="kt-privacypolicy-questionanswer-div" id="informationuse">
        <div className="kt-privacypolicy-question-div">How do we use them?</div>
        <div className="kt-privacypolicy-answer-div">
          <ul>
            <li>
              <b>Where strictly necessary.</b>
              <span>
                These cookies and other technologies are essential in order to
                enable the Services to provide the feature you have requested,
                such as remembering you have logged in.{" "}
              </span>
            </li>
            <li>
              <b>For functionality.</b>
              <span>
                These cookies and similar technologies remember choices you make
                such as language or search parameters. We use these cookies to
                provide you with an experience more appropriate with your
                selections and to make your use of the Services more tailored.{" "}
              </span>
            </li>
            <li>
              <b>For performance and analytics.</b>
              <span>
                These cookies and similar technologies collect information on
                how users interact with the Services and enable us to improve
                how the Services operate. For example, we use Google Analytics
                cookies to help us understand how visitors arrive at and browse
                our products and website to identify areas for improvement such
                as navigation, user experience, and marketing campaigns.
              </span>
            </li>
            <li>
              <b>Targeting Cookies or Advertising Cookies. </b>
              <span>
                 These cookies collect information about your browsing habits in
                order to make advertising relevant to you and your interests.
                They remember the websites you have visited and that information
                is shared with other parties such as advertising technology
                service providers and advertisers.
              </span>
            </li>
            <li>
              <b>Social media cookies. </b>
              <span>
                 These cookies are used when you share information using a
                social media sharing button or “like” button on our websites or
                you link your account or engage with our content on or through a
                social media site. The social network will record that you have
                done this. This information may be linked to
                targeting/advertising activities.
              </span>
            </li>
          </ul>
        </div>
      </div>
    );
  };

  const getPrivacyQuestion3Div = () => {
    return (
      <div
        className="kt-privacypolicy-questionanswer-div"
        id="informationshare"
      >
        <div className="kt-privacypolicy-question-div">
          How can you opt-out?
        </div>
        <div className="kt-privacypolicy-answer-div">
          <span>
            To opt-out of our use of cookies, you can instruct your browser, by
            changing its options, to stop accepting cookies or to prompt you
            before accepting a cookie from websites you visit. If you do not
            accept cookies, however, you may not be able to use all aspects of
            our Services. Dhan AI Inc. and our third party partners also collect
            information using web beacons (also known as “tracking pixels”).
            Many browsers include their own management tools for removing HTML5
            local storage objects. You may be able to opt out of receiving
            personalized advertisements as described in the Privacy Policy under
            “Your Choices.” You will not be able to opt-out of any cookies or
            other technologies that are “strictly necessary” for the Services.
          </span>
        </div>
      </div>
    );
  };

  const getPrivacyQuestion4Div = () => {
    return (
      <div className="kt-privacypolicy-questionanswer-div" id="cookieTracking">
        <div className="kt-privacypolicy-question-div">
          Updates to this Notice
        </div>
        <div className="kt-privacypolicy-answer-div">
          <span>
            This Cookies & Tracking Notice may be updated from time to time. If
            we make any changes, we will notify you by revising the “effective
            starting” date at the top of this notice.
          </span>
        </div>
      </div>
    );
  };

  const getPrivacyPolicyQuestionsDiv = () => {
    //this is bad but cant help for now
    return (
      <>
        <div className="kt-privacypolicy-divider" />
        {getPrivacyQuestion1Div()}
        <div className="kt-privacypolicy-divider" />
        {getPrivacyQuestion2Div()}
        <div className="kt-privacypolicy-divider" />
        {getPrivacyQuestion3Div()}
        <div className="kt-privacypolicy-divider" />
        {getPrivacyQuestion4Div()}
      </>
    );
  };

  return (
    <PageComponent>
      <div className="kt-background-grey-main-row">
        <div className="kt-privacypolicy-maindiv">
          <div className="kt-cookiepolicy-header-div">
            <span>Cookie Tracking Notice </span>
          </div>
          <div className="kt-privacypolicy-header-effective-date-div">
            <span>Effective Starting: July 20, 2019</span>
          </div>
          <div className="kt-privacypolicy-intro-div">
            {getIntroductionText()}
          </div>
          <div className="kt-privacypolicy-questions-div">
            {getPrivacyPolicyQuestionsDiv()}
          </div>
        </div>
        <div className="kt-background-white-main-row">
          <div className="kt-row-abstract">{getContactUsFormSection()}</div>
        </div>
      </div>
    </PageComponent>
  );
}
