import React from "react";
import PageComponent from "../AbstractPage";
import AppsMainSection from "./sections/AppsMainSection";
import AppsCustomerFocusSection from "./sections/AppsCustomerFocusSection";
import AppsEngExcelSection from "./sections/AppsEngExcelSection";
import AppsTransformSection from "./sections/AppsTransformSection";
import ContactFormSection from "../sections/ContactFormSection";

export default function ApproachPageComponent() {
  const getAppsMainSection = () => {
    return <AppsMainSection />;
  };

  const getAppsCustomerFocusSection = () => {
    return <AppsCustomerFocusSection />;
  };

  const getAppsEngExcelSection = () => {
    return <AppsEngExcelSection />;
  };

  const getAppsTransformSection = () => {
    return <AppsTransformSection />;
  };

  const getContactUsFormSection = () => {
    return <ContactFormSection />;
  };

  return (
    <PageComponent>
      <div className="kt-conversationalai">
        <div className="kt-background-white-main-row">
          <div className="kt-row-abstract">{getAppsMainSection()}</div>
        </div>

        <div className="kt-background-grey-main-row">
          <div className="kt-row-abstract">{getAppsCustomerFocusSection()}</div>
        </div>

        <div className="kt-background-white-main-row">
          <div className="kt-row-abstract">{getAppsEngExcelSection()}</div>
        </div>

        <div className="kt-background-grey-main-row">
          <div className="kt-row-abstract">{getAppsTransformSection()}</div>
        </div>

        <div className="kt-background-grey-main-row">
          <div className="kt-row-abstract">{getContactUsFormSection()}</div>
        </div>
      </div>
    </PageComponent>
  );
}
