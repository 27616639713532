import React from "react";
import { toAbsoluteUrl } from "../../../_metronic";
import "../../../_metronic/_assets/sass/pages/error/error-1.scss";

export function ErrorPage404() {
  return (
    <>
      <div className="kt-grid kt-grid--ver kt-grid--root">
        <div
          className="kt-grid__item kt-grid__item--fluid kt-grid kt-error-v1"
          style={{
            backgroundImage: `url(${toAbsoluteUrl("/media/error/bg1.jpg")})`
          }}
        >
          <div className="kt-error-v1__container">
            <h1 className="kt-error-v1__number">404</h1>
            <p className="kt-error-v1__desc">OOPS! Something went wrong here. The requested resource couldn't be found on this server. You can head to the <a href="/">homepage</a> or <a href="mailto:contact@dhan.ai">contact us</a>.</p>
          </div>
        </div>
      </div>
    </>
  );
}
