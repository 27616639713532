import React, { useMemo } from "react";
import PageComponent from "../AbstractPage";
import NLPSection from "./sections/NLPSection";
import NLPSpecialSection from "./sections/NLPSpecialSection";
import ContactFormSection from "../sections/ContactFormSection";

export default function CloudComponent() {
  const getNLPSection = () => {
    return <NLPSection />;
  };

  const getNlpSpecialSection = () => {
    return <NLPSpecialSection />;
  };

  const getContactUsFormSection = () => {
    return <ContactFormSection />;
  };

  return (
    <PageComponent>
      <div className="kt-conversationalai">
        <div className="kt-background-white-main-row">
          <div className="kt-row-abstract">{getNLPSection()}</div>
        </div>

        <div className="kt-background-white-main-row">
          <div className="kt-row-abstract">{getNlpSpecialSection()}</div>
        </div>

        <div className="kt-background-grey-main-row">
          <div className="kt-row-abstract">{getContactUsFormSection()}</div>
        </div>
      </div>
    </PageComponent>
  );
}
