import React, { useMemo } from "react";
import {Button} from "@material-ui/core";
import ImageSection from "../../sections/ImageSection";
import { toAbsoluteUrl } from "../../../../_metronic/utils/utils";

export default function NLPSpecialSection() {
  const getNLPIntegrateDiv = () => {
    return (
      <div className="kt-nlp-specialdiv">
        <div className="kt-nlp-imagediv-actimg">
          <img
            src={toAbsoluteUrl("/media/files/conversation-deploy-anywhere.svg")}
            alt="Conversational AI solutions"
          />
        </div>
        <div className="kt-nlp-specialdiv-header">
          Leverage Cloud to build next generation applications 
        </div>
        <div className="kt-nlp-specialdiv-description">
          <span>
            Our certified cloud experts, can support 
            you through the entire cloud journey, from 
            helping you develop your cloud migration strategy
            to assisting with your cloud migration to operations.
          </span>
        </div>
      </div>
    );
  };

  const getNLPExtractDiv = () => {
    return (
      <div className="kt-nlp-specialdiv">
        <div className="kt-nlp-imagediv-actimg">
          <img
            src={toAbsoluteUrl("/media/files/conversation-integrate-data.svg")}
            alt="Conversational AI solutions"
          />
        </div>
        <div className="kt-nlp-specialdiv-header">
          Efficiency & Speed 
        </div>
        <div className="kt-nlp-specialdiv-description">
          <span>
           Make your organizations more agile, lower your spend 
           and achieve efficiencies and speed to solutions by leveraging Cloud!
          </span>
        </div>
      </div>
    );
  };

  return (
    <ImageSection css_classname="kt-nlp-special-flex kt-nlp-special kt-section-height-816 kt-section-padding-bottom-88">
      {getNLPExtractDiv()}
      {getNLPIntegrateDiv()}
    </ImageSection>
  );
}
